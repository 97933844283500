.hamburger {
    text-align: end;
    padding: 20px 25px 10px 0px;
}

.ps-sidebar-container {
    background-color: white !important;
}

@media (max-Width: 600px) {
    .hamburger {
        padding: 20px 0px 10px 5px;
    }
}