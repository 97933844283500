.header{
    display: flex;
    justify-content: space-between;
}

.logout-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.logout-title {
    padding: 20px !important;
}

.orange-red-button-padd {
    padding: 20px;
    text-align: center;
}

.cancel-button {
    background-color: rgba(157, 155, 155, 0.8) !important;
    margin-left: 10px !important;
}

.divider {
    background-color: #F5A870;
}

/* .navbar-display {
    display: flex;
    column-gap: 10px;
} */

.navbar-content {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.menuitem-padding {
    padding: 0px !important;
}

.pin-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
    padding-bottom: 30px;
}