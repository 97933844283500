.details-box {
    padding: 10px 0px 20px 20px;
}

.details-info {
    display: flex;
}

.details-label {
    font-weight: 600 !important;
}

.log-container {
    display: flex;
    padding-top: 7px;
}

.copy-button{
    padding-top: 12px !important;
}

@media (max-Width: 403px) {
    .details-info {
        flex-direction: column;
        padding-bottom: 10px;
    }
    
    .copy-button{
        padding-top: 0px !important;
    }
}