.sum-text {
    font-size: 14px !important;
    color: #747474;
    text-align: center;
}

.sum-data {
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #747474;
    text-align: center;
}

.sum-box{
    padding: 10px;
}

.collapsibleCard {
    box-shadow: 1px 2px 5px #cccccc !important;
}

.collapsibleCardInfoSection {
    border-bottom: 1px solid lightgrey;
    padding-top: 5px;
}

.collapsibleCardTop10 {
    font-size: 15px !important;
    color: #747474;
    /* width: 28%; */
    width: 40%;
    padding: 5px 0px 0px 30px;
}

.divider_shorter {
    height: 22px !important;
}

.top10BoxInBox {
    display: flex;
    width: 100% !important;
}

.collapsibleCardResult {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #747474;
    width: 50%;
    padding: 5px 0px 0px 30px;
    border-left: 1px soild lightgrey;
}

.divider_shorterinBox {
    height: 22px !important;
}


@media (max-width: 425px) {
    .collapsibleCardTop10 {
        padding: 5px 0px 0px 15px;
    }

    .collapsibleCardResult {
        padding: 5px 0px 0px 15px;
    }

    .top10BoxInBox {
        flex-direction: column;
        padding-left: 15px;
    }

    .divider_shorter {
        height: 50px !important;
        margin-left: 15px !important;
    }

    .divider_shorterinBox {
        display: none;
    }
}