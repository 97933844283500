.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    color: #393636 !important;
    font-weight: bold !important;
}

.error {
    font-size: 12px;
    color: red;
}

@media (max-width: 375px) {
    .form {
        gap: 20px !important;
    }
}